import moment from 'moment';

import { cartStorageName, defaultLabStorageName, userStorageName } from '@/services/ecommerce/apis/config';
import { DateTime } from 'luxon';
import { FaBriefcase, FaHouse } from 'react-icons/fa6';
import { MdOutlineApartment } from 'react-icons/md';
import { getItemFromLocalStorage, setItemInLocalStorage } from './session';

import type { I_VendorLabItem } from '@labServices/apis/types/lab-tests/type.getPopularLabPackages';
import type { IF_VendorPriceResult } from './type.cmnHelper';

export const calculatePercentage = (d_price: any, price: any) =>
	!!parseFloat(price) && !!parseFloat(d_price) ? (100 - (d_price / price) * 100).toFixed(0) : 0;

export function validPhoneNumber(phone: any) {
	const regex = /(^(\+8801|8801|008801|01))(\d){9}$/;
	return regex.test(phone);
}

export const getQtyLabel = (i: number, medicine: any) => {
	if (!medicine) {
		return '';
	}
	if (medicine.form === medicine.unit) {
		const s = i === 1 ? '' : 's';
		return `${i} ${medicine.unit}${s}`;
	}
	const match = medicine.unit.match(/([0-9]+){1,3}/);
	const qty = match ? match[0] : 0;
	if (qty && `${qty} ${medicine.form}s` === medicine.unit) {
		return `${i * qty} ${medicine.form}s`;
	}
	return `${i} * ${medicine.unit}`;
};

export const textToSlug = (text: any): string =>
	text
		.toString()
		.trim()
		.toLowerCase()
		.replace(/\s+/g, '-')
		.replace(/[^\w\-]+/g, '')
		.replace(/\-\-+/g, '-')
		.replace(/^-+/, '')
		.replace(/-+$/, '');

export const slugToText = (slug: string): string => {
	const text = slug?.replace(
		/(^|-)([a-z])/g,
		(_, separator, letter) => (separator === '-' ? ' ' : '') + letter.toUpperCase()
	);
	return text.replace(/\s+/g, '+');
};

export const titleToSlug = (brand: { name?: string; form?: string; strength?: string }): string => {
	const text = `${brand.name || ''}-${brand.form || ''}-${brand.strength || ''}`;
	return textToSlug(text);
};

export const setLocalData = (data: any, type: 'user' | 'cart') => {
	if (typeof window !== 'undefined') {
		if (type === 'cart') {
			setItemInLocalStorage(cartStorageName, data);
		} else if (type === 'user') {
			setItemInLocalStorage(userStorageName, data);
		}
	} else {
		console.log('Window Not Found!');
	}
};

export const isEmptyArray = (array: any[] | undefined) => {
	if (Array.isArray(array) && array.length > 0) {
		return false;
	}
	return true;
};

export const isEmptyObject = (obj: object | undefined) => {
	if (obj) {
		if (Object.keys(obj).length > 0) {
			return false;
		}
	}
	return true;
};

export const isLoggedInUser = () => {
	const isLogggedId = getItemFromLocalStorage(userStorageName);
	if (Boolean(isLogggedId)) return true;
	else false;
};

export const getFlatNumber = (num: number | string = 0) => {
	return num.toString().split('.')[0];
};

export const isServer = () => typeof window === 'undefined';

export const luxonFormattedDate = (isoTimestamp: any, onlyDate = false) => {
	if (!isoTimestamp) return 'N/A';

	if (onlyDate === true) {
		return DateTime.fromISO(isoTimestamp).toFormat('dd/LL/yyyy');
	}

	return DateTime.fromISO(isoTimestamp).toFormat('dd/LL/yyyy hh:mm a');
};

export const getFormattedDate = (isoTimestamp: any) => {
	if (!isoTimestamp) return 'N/A';

	const dateInstance = new Date(isoTimestamp);

	return moment(dateInstance).format('DD MMM, YYYY');
};
export const getFormattedDateTime = (isoTimestamp: any) => {
	if (!isoTimestamp) return 'N/A';

	const dateInstance = new Date(isoTimestamp);

	return moment(dateInstance).format('DD MMM, YYYY, hh:mm A');
};

export function toQueryString(obj: any): string {
	const queryStringParts = [];

	for (const [key, value] of Object.entries(obj)) {
		if (Array.isArray(value)) {
			queryStringParts.push(`${key}=${encodeURIComponent(JSON.stringify(value))}`);
		} else {
			queryStringParts.push(`${key}=${encodeURIComponent(value as any)}`);
		}
	}

	const queryString = queryStringParts.join('&');

	return queryString;
}

export function getFormData(property: any, isArray?: boolean | string): FormData {
	const formData = new FormData();

	if (typeof property === 'object') {
		Object.keys(property).forEach((key) => {
			const value = property[key];
			if (Array.isArray(value)) {
				value.forEach((item) => {
					if (!!isArray) {
						formData.append(`${key}[]`, item);
					} else {
						formData.append(key, item);
					}
				});
			} else {
				formData.append(key, value);
			}
		});
	} else {
		formData.append('file', property);
	}

	return formData;
}

export function popupWindow(url: string, title: string, win: any, width: number, height: number) {
	const y = win.top.outerHeight / 2 + win.top.screenY - height / 2;
	const x = win.top.outerWidth / 2 + win.top.screenX - width / 2;
	return win.open(
		url,
		title,
		`toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`
	);
}

export const monitorPopupWindow = (
	url: string,
	title: string,
	win: any,
	width: number,
	height: number,
	callback?: () => void
) => {
	const popupWindowRef = popupWindow(url, title, win, width, height);

	const checkWindowStatus = () => {
		if (popupWindowRef && !popupWindowRef.closed) {
			setTimeout(checkWindowStatus, 200);
		} else {
			callback && callback();
		}
	};

	checkWindowStatus();
};

export function getFormattedLabVendorItemAndPrice(
	labVendorItem?: I_VendorLabItem | null,
	labVendorItems?: I_VendorLabItem[] | null,
	cartVendorId?: string | null
) {
	const defaultVendor: {
		labItem?: I_VendorLabItem;
		testPrice?: IF_VendorPriceResult;
		isUnavailable?: boolean;
		hintMessage?: string;
	} = {};

	if (labVendorItem) {
		defaultVendor.labItem = labVendorItem;
	}
	if (!isEmptyArray(labVendorItems)) {
		if (cartVendorId) {
			const testItem = labVendorItems.find(
				(vendorItem) =>
					(vendorItem?.vendor?.id === cartVendorId || vendorItem?.vendor?.tag === cartVendorId) &&
					vendorItem?.status === 'active'
			);
			if (testItem) {
				defaultVendor.labItem = testItem;
			}
		} else {
			const testItem = labVendorItems?.find(
				(vendorItem) => vendorItem?.vendor?.isDefault === true && vendorItem?.status === 'active'
			);
			if (testItem) {
				defaultVendor.labItem = testItem;
				setItemInLocalStorage(defaultLabStorageName, {
					id: testItem?.vendor?.id,
					tag: testItem?.vendor?.tag,
					name: testItem?.vendor?.name?.en
				});
			}
		}
	}

	if (defaultVendor?.labItem) {
		const regularPrice = parseFloat(defaultVendor?.labItem?.regularPrice);
		const discountPrice = parseFloat(defaultVendor?.labItem?.discountPrice);
		const discountPercent = parseFloat(defaultVendor?.labItem?.discountPercent);

		let finalDiscountPrice: number = regularPrice;
		let discountPercentValue: number = 0;
		let hasDiscountValue: boolean = false;

		if (discountPrice > 0 && discountPrice < regularPrice) {
			finalDiscountPrice = discountPrice;
			discountPercentValue = discountPercent;
			hasDiscountValue = true;
		}

		defaultVendor['testPrice'] = {
			regularPrice: regularPrice.toFixed(0) === 'NaN' ? '' : regularPrice.toFixed(0),
			discountPrice: finalDiscountPrice.toFixed(0) === 'NaN' ? '' : finalDiscountPrice.toFixed(0),
			discountPercent: discountPercentValue.toFixed(0) === 'NaN' ? '' : discountPercentValue.toFixed(0),
			hasDiscount: hasDiscountValue
		};
	} else {
		defaultVendor['isUnavailable'] = isEmptyObject(defaultVendor) ? true : false;
		defaultVendor['hintMessage'] = 'Offered by other lab partners';
	}
	return defaultVendor;
}

export const getMatchedAndUnmatchedVendors = (items: I_VendorLabItem[], cartVendorId: string) => {
	const matchedVendors: I_VendorLabItem[] = [];
	const unmatchedVendors: I_VendorLabItem[] = [];

	items?.forEach((item) => {
		if (item?.vendor?.id === cartVendorId || item?.vendor?.tag === cartVendorId) {
			matchedVendors.push(item);
		} else {
			unmatchedVendors.push(item);
		}
	});
	return { matchedVendors, unmatchedVendors };
};

export const getVariant = (status: string) => {
	switch (status) {
		// lab-Test Status variants
		case 'pending':
		case 'confirmed':
			return 'bgSeashell';
		case 'processing':
		case 'collected':
		case 'tested':
			return 'bgCosmicLatte';
		case 'completed':
			return 'bgBrightGray';
		case 'cancelled':
			return 'bgLavenderBlush';
		case 'scheduled':
		case 'rescheduled':
			return 'bgPlaceboBlue';
		default:
			return 'bgPrimary';
	}
};

export const getColor = (status: string) => {
	switch (status) {
		// Lab-test Status colors

		case 'pending':
		case 'confirmed':
			return 'clOrange';
		case 'processing':
		case 'collected':
		case 'tested':
			return 'clSunglow';
		case 'completed':
			return 'clSuccessDark';
		case 'cancelled':
			return 'clErrorBase';
		case 'scheduled':
		case 'rescheduled':
			return 'clBlueRibbon';
		default:
			return 'clAntiFlashWhite';
	}
};

export const getText = (status: string) => {
	switch (status) {
		// Lab-test Status Text

		case 'scheduled':
		case 'rescheduled':
			return 'Scheduled';
		case 'pending':
		case 'confirmed':
			return 'Pending';
		case 'processing':
		case 'collected':
		case 'tested':
			return 'Processing';
		case 'completed':
			return 'Completed';
		case 'cancelled':
			return 'Cancelled';
		default:
			return 'Pending';
	}
};

export const getStatus = (status: string) => {
	switch (status) {
		// Lab-test Status

		case 'pending':
			return true;
		case 'confirmed':
			return true;
		case 'scheduled':
			return true;
		case 'rescheduled':
			return true;
		default:
			return false;
	}
};

export const getAddressIcon = (type: string) => {
	switch (type) {
		case 'Home':
			return (
				<FaHouse
					size={24}
					color='var(--ltGreyscale600)'
					style={{
						minWidth: '24px',
						maxWidth: '24px'
					}}
				/>
			);
		case 'Office':
			return (
				<FaBriefcase
					size={24}
					color='var(--ltGreyscale600)'
					style={{
						minWidth: '24px',
						maxWidth: '24px'
					}}
				/>
			);
		case 'Home Town':
			return (
				<MdOutlineApartment
					size={24}
					color='var(--ltGreyscale600)'
					style={{
						minWidth: '24px',
						maxWidth: '24px'
					}}
				/>
			);
		default:
			return (
				<FaHouse
					size={24}
					color='var(--ltGreyscale600)'
					style={{
						minWidth: '24px',
						maxWidth: '24px'
					}}
				/>
			);
	}
};

export const getIncludeType = (type: string, count: number | string) => {
	switch (type) {
		case 'test':
			return `${count} ${Number(count) <= 1 ? 'Test' : 'Tests'}`;
		case 'package':
			return `${count} ${Number(count) <= 1 ? 'Package' : 'Packages'}`;
		case 'patient':
			return `${count} ${Number(count) <= 1 ? 'Patient' : 'Patients'}`;
		default:
			return '';
	}
};
