'use client';

import AroggaAPI from '@/services/ecommerce/apis/AroggaAPI';
import usePixalEvent from '@/services/event/usePixalEvent';

import { useAlert } from '@/contexts/AlertProvider';
import { API_ACCOUNT, API_PUBLIC, AUTH } from '@/services/ecommerce/apis/apiConstrant';
import { cartStorageName, locationStorageName, userStorageName } from '@/services/ecommerce/apis/config';
import { get, omit } from 'lodash';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { ReactNode, Suspense, createContext, useContext, useEffect, useState } from 'react';

interface User {
	id: number;
	name: string;
	b2b_status: {
		status: string;
	};
	authToken: string;
	u_pharmacy_id: number;
	u_name: string;
	u_mobile: string;
	[key: string]: any;
}

const UpdateSearch = () => {
	const search = useSearchParams();
	const pathname = usePathname();
	const { page_view } = usePixalEvent();

	useEffect(() => {
		const searchParams = new URLSearchParams(search as any);
		const url = `${pathname}?${searchParams.toString()}`;

		page_view(url);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, pathname]);

	return null;
};

interface AuthContextProps {
	user: User | null;
	userLoginAction: (data: { user: User; b2b_status: { status: string } }) => void;
	isLogged: boolean;
	isB2B?: boolean;
	logout: () => void;
	updateProfile: (userData: any) => Promise<boolean>;
	loading: boolean;
	getUserProfile: () => Promise<boolean>;
	getBalance: () => void;
	menus: any;
	setMenus: any;
	FlashSale: any;
}
const AuthContext = createContext<AuthContextProps | undefined>(undefined);

const AuthProvider = ({ children }: { children: ReactNode }) => {
	const router: any = useRouter();
	const pathname = usePathname();
	const [loading, setLoading] = useState<boolean>(true);
	const [user, setUser] = useState<User | null>(null);
	const [isLogged, setIsLogged] = useState<boolean>(false);
	const [isB2B, setIsB2B] = useState<boolean>(false);
	const [menus, setMenus] = useState<any>([]);
	const [FlashSale, setFlashSale] = useState<any>([]);
	const alert = useAlert();

	const userLoginAction = (data: { user: User; b2b_status: { status: string }; authToken: string }) => {
		const updatedUser = {
			...data.user,
			authToken: data.authToken,
			b2b_status: data.b2b_status
		};

		localStorage.setItem(userStorageName, JSON.stringify(updatedUser));
		setIsLogged(true);
		setUser(updatedUser);
		setIsB2B(data?.b2b_status?.status === 'approved' && data.user.u_pharmacy_id !== 0);
	};

	useEffect(() => {
		const userJson = localStorage.getItem(userStorageName);
		if (userJson && typeof window !== 'undefined') {
			try {
				const storedUser = JSON.parse(userJson) as User;
				if (storedUser.authToken) {
					setIsLogged(true);
					if (storedUser) {
						setIsB2B(storedUser?.b2b_status?.status === 'approved' && storedUser?.u_pharmacy_id !== 0);
					} else {
						localStorage.removeItem(userStorageName);
					}
				}
			} catch (error) {
				// userJson is not a valid JSON or there's another error while parsing
				localStorage.removeItem(userStorageName);
			}
			if (userJson) {
				setUser(JSON.parse(userJson));
			}
		}
		setLoading(false);
	}, []);

	const logout = () => {
		AroggaAPI.post(AUTH.USER_LOGOUT).then((res) => {
			localStorage.removeItem(userStorageName);
			localStorage.removeItem(locationStorageName);
			localStorage.removeItem(cartStorageName);
			setIsLogged(false);
			setUser(null);
			setIsB2B(false);
			router.push('/');
		});
	};

	const updateProfile = async (userData: any) => {
		try {
			const { data, status, message } = await AroggaAPI.post(API_ACCOUNT.USER_PROFILE, {
				...omit(userData, ['u_mobile', 'u_email'])
			});

			if (status === 'fail') {
				alert.error({
					message: message,
					title: 'Error'
				});
				return false;
			}
			getUserProfile();
			// localStorage.setItem(userStorageName, JSON.stringify(data.user));
			// setUser(data.user);
			alert.success({
				message: 'Profile updated successfully',
				title: 'Success'
			});
		} catch (error) {
			console.log(error);
		}
		return true;
	};

	const getUserProfile = async () => {
		try {
			const { data, status, message } = await AroggaAPI.get(API_ACCOUNT.USER_PROFILE);
			if (status === 'fail') {
				return false;
			}
			const userJson = localStorage.getItem(userStorageName);
			if (userJson) {
				const storedUser = JSON.parse(userJson) as User;
				localStorage.setItem(userStorageName, JSON.stringify({ ...storedUser, ...data.user }));
				setUser({ ...storedUser, ...data.user });
			}
		} catch (error) {
			console.log(error);
		}
		return true;
	};
	const getBalance = async () => {
		const { data, status } = await AroggaAPI.get(API_ACCOUNT.CASH_BALANCE);
		if (status === 'success') {
			const userJson = localStorage.getItem(userStorageName);

			if (userJson) {
				const storedUser = JSON.parse(userJson) as User;
				localStorage.setItem(userStorageName, JSON.stringify({ ...storedUser, ...data }));
				setUser({ ...storedUser, ...data });
			}
		} else {
			console.log('error');
		}
	};
	const CheckFlashSale = async () => {
		try {
			const { data } = await AroggaAPI.get(API_PUBLIC.GET_FLASH_SELL);
			const first = get(data, '[0].block_data', {});
			setFlashSale({
				...first,
				block: data[0]
			});
		} catch (error) {
			setFlashSale({
				total: 0
			});
		}
	};
	useEffect(() => {
		CheckFlashSale();
	}, []);
	useEffect(() => {
		isLogged && getBalance();
	}, [isLogged, pathname]);
	return (
		<AuthContext.Provider
			value={{
				user,
				userLoginAction,
				isLogged,
				isB2B,
				logout,
				updateProfile,
				loading,
				getUserProfile,
				getBalance,
				menus,
				setMenus,
				FlashSale
			}}>
			<Suspense fallback={null}>
				<UpdateSearch />
			</Suspense>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };

const useAuth = () => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error('useAuth must be used within an AuthProvider');
	}
	return context;
};

export default useAuth;
